import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./global-slice";
import listsSlice from "./lists-slice";
import authSlice from "./auth-slice";
import { listsAPIService } from "../services/lists-service";
import { authService } from "../services/auth-service";

const store = configureStore({
  reducer: {
    global: globalSlice,
    lists: listsSlice,
    auth: authSlice,
    [listsAPIService.reducerPath]: listsAPIService.reducer,
    [authService.reducerPath]: authService.reducer,
  },
  middleware: (middleware) =>
    middleware({ serializableCheck: false })
      .concat(listsAPIService.middleware)
      .concat(authService.middleware),
});

export default store;
