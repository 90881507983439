import ContactForm from "../components/contact-form";
const Contact = () => {
  return (
    <div className="body__container">
      {/* <CommentsList comments={data} isLoading={isLoading} error={error} /> */}
      <ContactForm />
    </div>
  );
};
export default Contact;
