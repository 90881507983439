import { useState } from "react";
import {
  providencesList,
  districtsList,
  muncipalsList,
} from "../../utils/addresses";
/* permanent address: street, muncipality, district, providence, country */
const AddressInfo = () => {
  const [info, setInfo] = useState({
    country: "NP",
    providence: 0,
    district: 0,
    muncipality: 0,
    street: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(info);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form__group">
        <select
          placeholder="Country"
          value={info.country}
          onChange={(e) => setInfo({ ...info, country: e.target.value })}
        >
          <option value="" disabled>
            Select Country
          </option>
          <option value="NP">Nepal</option>
        </select>
        <select
          value={info.providence}
          required
          onChange={(e) => {
            setInfo({
              ...info,
              providence: Number(e.target.value),
              district: 0,
              muncipality: 0,
            });
          }}
        >
          <option value="">Select Province</option>

          {providencesList.map((p, i) => (
            <option key={i} value={p.id}>
              {p.name}
            </option>
          ))}
        </select>
        <select
          placeholder="District"
          value={info.district}
          required
          onChange={(e) => {
            setInfo({
              ...info,
              district: Number(e.target.value),
              muncipality: 0,
            });
          }}
          disabled={info.providence === 0}
        >
          <option value="">Select District</option>
          {districtsList
            .filter((e) => e.province_id === info.providence)
            .map((p, i) => (
              <option key={i} value={p.id}>
                {p.name}
              </option>
            ))}
        </select>
        <select
          placeholder="Muncipality"
          value={info.muncipality}
          onChange={(e) =>
            setInfo({ ...info, muncipality: Number(e.target.value) })
          }
          disabled={info.district === 0}
          required
        >
          <option value="">Select Muncipal</option>
          {muncipalsList
            .filter((p) => p.district_id === info.district)
            .map((p, i) => (
              <option key={i} value={p.id}>
                {p.name}
              </option>
            ))}
        </select>
      </div>
      <input
        type="text"
        placeholder="Address"
        value={info.street}
        required
        onChange={(e) => {
          setInfo({
            ...info,
            street: e.target.value,
          });
        }}
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default AddressInfo;
