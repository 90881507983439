import Header from "./components/app/header";
import Footer from "./components/app/footer";
import BottomAppbar from "./components/app/bottom-appbar";
import { Outlet } from "react-router-dom";
import Loader from "./components/loader";
import { Suspense, useEffect } from "react";
import AuthPopup from "./components/auth/auth-popup";
// import ForgotPassword from "./components/auth/forgot-password";
import { useDispatch, useSelector } from "react-redux";
import {
  toastReducer,
  authPopupReducer,
  loadingReducer,
  userReducer,
} from "./redux/reducers";
import ToastComponent from "./components/toast-component";
import { updateUser } from "./redux/auth-slice";
const App = () => {
  const dispatch = useDispatch();
  const loading = useSelector(loadingReducer);
  const toast = useSelector(toastReducer);
  const authPopup = useSelector(authPopupReducer);
  const user = useSelector(userReducer);
  useEffect(() => {
    // header shadow event
    const handleHeaderShadow = () => {
      const header = document.querySelector("header");
      if (window.screenY > 0) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    };
    window.addEventListener("scroll", handleHeaderShadow);

    // handle storage event
    const handleStorageEvent = (e) => {
      console.log(e);
      if (e.key === "user") {
        if (e.newValue !== null || e.newValue !== undefined) {
          dispatch(updateUser(JSON.parse(e.newValue)));
        } else {
          dispatch(updateUser(null));
        }
      }
    };
    // window.addEventListener("storage", handleStorageEvent);

    // cleanup
    return () => {
      window.removeEventListener("scroll", handleHeaderShadow);
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <ToastComponent />
        <div className="app flex flex-col align-center justify-center">
          <Header />
          <section>
            <Outlet />
          </section>
          <Footer />
          {user && <BottomAppbar />}
        </div>
      </Suspense>
      {authPopup && <AuthPopup />}
      {/* {<ForgotPassword />} */}
      {toast && <ToastComponent />}
      {loading && <Loader />}
    </>
  );
};

export default App;
