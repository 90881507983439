import { NavLink } from "react-router-dom";
import { profileMenuItems } from "../../utils/constants";

const BottomAppbar = () => {
  return (
    <div className="bottom__appbar flex align-center justify-between">
      {profileMenuItems.map((e, i) => (
        <NavLink key={i} to={e.path}>
          {e.icon}
          <div>{e.title}</div>
        </NavLink>
      ))}
    </div>
  );
};

export default BottomAppbar;
