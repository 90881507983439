import AlbumsList from "../components/albums-list";
import { useGetAlbumsQuery } from "../services/lists-service";

const Careers = () => {
  const { isLoading, data, error } = useGetAlbumsQuery();

  return (
    <div className="body__container">
      <AlbumsList albums={data} isLoading={isLoading} error={error} />
    </div>
  );
};
export default Careers;
