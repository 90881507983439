import CoursesList from "../components/courses-list";
const _list1 = [
  "CTEVT",
  "Brigde Course",
  "Language Class",
  "Computer Class",
  "Accounting Package",
];
const _list2 = [
  "MBBS",
  "Paramedical",
  "Engineering",
  "Management(CMAT)",
  "LAW",
];

const Courses = () => {
  return (
    <div className="body__container flex flex-col gap4">
      <CoursesList title="Course Offerred After SEE/SLC" lists={_list1} />
      <CoursesList title="Course Offerred After +2" lists={_list2} />
    </div>
  );
};

export default Courses;
