import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./index.scss";
import "aos/dist/aos.css";
import AOS from "aos";
import App from "./App";
import store from "./redux/store";
import Home from "./pages/home";
import Services from "./pages/services";
import About from "./pages/about";
import Contact from "./pages/contact";
import Careers from "./pages/careers";
import Courses from "./pages/courses";
import CourseDetail from "./pages/course-detail";
import PageNotFound from "./pages/page-not-found";
import {
  ABOUT_ROUTE,
  CAREERS_ROUTE,
  CONTACT_ROUTE,
  HOME_ROUTE,
  SERVICES_ROUTE,
  MYCOURSES_ROUTE,
  DASHBOARD_ROUTE,
  NOTIFICTION_ROUTE,
  PROFILE_ROUTE,
  COURSES_ROUTE,
  EVENTS_ROUTE,
  PRIVACY_ROUTE,
  TERMS_OF_USE_ROUTE,
} from "./utils/routes";
import { Provider, useSelector } from "react-redux";
import MyCourses from "./pages/my-courses";
import Dashboard from "./pages/dashboard";
import Notifcation from "./pages/notification";
import Profile from "./pages/profile";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsOfUse from "./pages/terms-of-use";
import { userReducer } from "./redux/reducers";

const PrivateRoute = ({ children }) => {
  const user = useSelector(userReducer);
  return user ? children : <Navigate to="/" />;
};

const router = createBrowserRouter([
  {
    path: HOME_ROUTE,
    element: <App />,
    children: [
      { path: "", index: true, element: <Home /> },
      { path: SERVICES_ROUTE, element: <Services /> },
      { path: ABOUT_ROUTE, element: <About /> },
      { path: CONTACT_ROUTE, element: <Contact /> },
      { path: CAREERS_ROUTE, element: <Careers /> },
      {
        path: PROFILE_ROUTE,
        element: (
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        ),
      },
      {
        path: MYCOURSES_ROUTE,
        element: (
          <PrivateRoute>
            <MyCourses />
          </PrivateRoute>
        ),
      },
      {
        path: DASHBOARD_ROUTE,
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: NOTIFICTION_ROUTE,
        element: (
          <PrivateRoute>
            <Notifcation />
          </PrivateRoute>
        ),
      },
      { path: COURSES_ROUTE, element: <Courses /> },
      { path: `${COURSES_ROUTE}/:id`, element: <CourseDetail /> },
      { path: EVENTS_ROUTE, element: <div>events</div> },
      { path: PRIVACY_ROUTE, element: <PrivacyPolicy /> },
      { path: TERMS_OF_USE_ROUTE, element: <TermsOfUse /> },
      { path: "*", element: <PageNotFound /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
AOS.init();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
