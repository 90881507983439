import Loader from "./loader";

const UsersList = ({ users = [], isLoading = false, error = null }) => {
  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;
  return (
    <div className="users__list grid gap4">
      {users.map((user) => (
        <div
          key={user.id}
          className="user__card p4 radius2 flex flex-col gap2"
          data-aos="fade-up"
        >
          <div>
            <h2>{user.name}</h2>
            <p>Username: {user.username}</p>
            <p>Email: {user.email}</p>
            <p>Phone: {user.phone}</p>
          </div>
          <div>
            <p>
              Address: {user.address.suite}, {user.address.street},
              {user.address.city}, {user.address.zipcode}
            </p>
            <p>Website: {user.website}</p>
          </div>
          <div>
            <p>Company: {user.company.name}</p>
            <p>Catchphrase: {user.company.catchPhrase}</p>
            <p>BS: {user.company.bs}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default UsersList;
