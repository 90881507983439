import { useGetUsersQuery } from "../services/lists-service";
import UsersList from "../components/users-list";

const About = () => {
  const { isLoading, data, error } = useGetUsersQuery();

  return (
    <div className="body__container">
      <UsersList users={data} isLoading={isLoading} error={error} />
    </div>
  );
};
export default About;
