import { useState } from "react";
/* parent info: first name, middle name, last name, email, phone, relation */

const _relations = [
  "Father",
  "Mother",
  "Brother",
  "Sister",
  "Uncle",
  "Aunty",
  "Other",
];

const GaurdianInfo = () => {
  const [info, setInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    relation: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="form__group">
        <input
          type="text"
          placeholder="First Name"
          value={info.firstName}
          required
          onChange={(e) => setInfo({ ...info, firstName: e.target.value })}
        />
        <input
          type="text"
          placeholder="Middle Name"
          value={info.middleName}
          onChange={(e) => setInfo({ ...info, middleName: e.target.value })}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={info.lastName}
          required
          onChange={(e) => setInfo({ ...info, lastName: e.target.value })}
        />
        <input
          type="email"
          placeholder="Email"
          value={info.email}
          onChange={(e) => setInfo({ ...info, email: e.target.value })}
        />
        <input
          type="text"
          placeholder="Phone"
          value={info.phone}
          required
          onChange={(e) => setInfo({ ...info, phone: e.target.value })}
        />
        <select
          value={info.relation}
          onChange={(e) => setInfo({ ...info, relation: e.target.value })}
          required
        >
          <option value="" disabled>
            Select Relation
          </option>
          {_relations.map((x, i) => (
            <option key={i} value={x.toLocaleUpperCase()}>
              {x}
            </option>
          ))}
        </select>
      </div>
      <button>Submit</button>
    </form>
  );
};

export default GaurdianInfo;
