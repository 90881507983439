import PhotosList from "../components/photos-list";
import { useGetPhotosQuery } from "../services/lists-service";

const Services = () => {
  const { isLoading, data, error } = useGetPhotosQuery();

  return (
    <div className="body__container">
      <PhotosList photos={data} isLoading={isLoading} error={error} />
    </div>
  );
};
export default Services;
