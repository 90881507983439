import { NavLink } from "react-router-dom";
import { otherMenuItems, socialsItems } from "../../utils/constants";
import { PRIVACY_ROUTE, TERMS_OF_USE_ROUTE } from "../../utils/routes";

const support = [
  { title: "Terms of Use", path: TERMS_OF_USE_ROUTE },
  { title: "Privacy Statement", path: PRIVACY_ROUTE },
];
const company = [
  "Navigate your next",
  "About Us",
  "Careers",
  "ESG",
  "Investors",
  "Newsroom",
  "Alumni",
];

const Footer = () => {
  return (
    <footer>
      <div className="body__container" style={{ padding: "40px 16px" }}>
        <div className="footer">
          <div className="footer__address">
            <div>Study2ool ©{new Date().getFullYear()}</div>
            <p>
              4010, New, Krishna Rajendra Rd, Siddanna Layout, Banashankari
              Stage II, Banashankari, Bengaluru, Karnataka 560070
            </p>
          </div>
          <div className="flex flex-col align-start gap2">
            <h2>Company</h2>
            <div className="flex flex-col align-start gap1">
              {otherMenuItems.map((s, i) => (
                <NavLink key={i} to={s.path}>
                  {s.title}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="flex flex-col align-start gap2">
            <h2>Company</h2>
            <div className="flex flex-col align-start gap1">
              {company.map((c, i) => (
                <NavLink key={i}>{c}</NavLink>
              ))}
            </div>
          </div>
          <div className="flex flex-col align-start gap2">
            <h2>Suuport</h2>
            <div className="flex flex-col align-start gap1">
              {support.map((s, i) => (
                <NavLink key={i} to={s.path}>
                  {s.title}
                </NavLink>
              ))}
            </div>
          </div>
        </div>
        <div
          className="my8"
          style={{ background: "rgba(0, 0, 0, 0.1)", height: "1px" }}
        />
        <div className="footer__bottom flex align-center justify-between">
          <div style={{ color: "#000000a3" }}>
            Copyrights ©{new Date().getFullYear()}
          </div>
          <div className="flex align-center justify-center gap1">
            {socialsItems.map((e, i) => (
              <a
                key={i}
                className="flex align-center justify-center radius4"
                target="_blank"
                rel="noreferrer"
                href={`https://${e.key}.com`}
              >
                {e.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
