import Loader from "./loader";

const PostsList = ({ posts = [], isLoading = false, error }) => {
  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;
  return (
    <div className="posts__lists grid gap3">
      {posts.map((post) => (
        <div
          key={post.id}
          className="post__card p4 radius2 flex flex-col gap1"
          data-aos="fade-up"
        >
          <h2>{post.title}</h2>
          <p>{post.body}</p>
        </div>
      ))}
    </div>
  );
};

export default PostsList;
