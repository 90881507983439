import "../css/_toast.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateToast } from "../redux/global-slice";
import {
  toastReducer,
  toastMessageReducer,
  toastTypeReducer,
} from "../redux/reducers";
import { CheckCircle2, Info, XCircle } from "lucide-react";
import { useEffect } from "react";

// info = 0, success = 1, error = 2
const ToastComponent = () => {
  const dispatch = useDispatch();
  const toast = useSelector(toastReducer);
  const message = useSelector(toastMessageReducer);
  const type = useSelector(toastTypeReducer);

  useEffect(() => {
    setTimeout(() => {
      dispatch(updateToast({ toast: false, message: "", type: 0 }));
    }, 5000);
  }, [toast]);

  return (
    toast && (
      <div className="custom__toast__container">
        <div className="custom__toast flex align-center gap2 radius1 px4 py3 mx4">
          <div>
            {type === 0 && <Info size={16} color="grey" />}
            {type === 1 && <CheckCircle2 size={16} color="green" />}
            {type === 2 && <XCircle size={16} color="red" />}
          </div>
          <p>{message}</p>
          <button
            className="custom__toast__close"
            onClick={() => dispatch(updateToast(false))}
          >
            &times; {/* Close icon */}
          </button>
        </div>
      </div>
    )
  );
};

export default ToastComponent;
