import Loader from "./loader";

const AlbumsList = ({ albums = [], isLoading = false, error }) => {
  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;
  return (
    <div className="posts__lists grid gap3">
      {albums.map((album) => (
        <div
          key={album.id}
          className="post__card p4 radius2 flex flex-col gap1"
          data-aos="fade-up"
        >
          <h2>{album.title}</h2>
        </div>
      ))}
    </div>
  );
};

export default AlbumsList;
