export const loadingReducer = (state) => state.global.loading;
export const authPopupReducer = (state) => state.global.authPopup;
export const toastReducer = (state) => state.global.showToast;
export const toastMessageReducer = (state) => state.global.toastMessage;
export const toastTypeReducer = (state) => state.global.toastType;
export const postsReducer = (state) => state.lists.posts;
export const commentsReducer = (state) => state.lists.comments;
export const albumsReducer = (state) => state.lists.albums;
export const photosReducer = (state) => state.lists.photos;
export const todosReducer = (state) => state.lists.todos;
export const usersReducer = (state) => state.lists.users;
// auth
export const userReducer = (state) => state.auth.user;
