import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  },
  reducers: {
    updateUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
    },
  },
});

export const { updateUser, logout } = authSlice.actions;
export default authSlice.reducer;

// login user
// export const loginUser = (email, password) => async (dispatch) => {
//   try {
//     dispatch(updateLoading(true));
//     const user = { email, password };
//     dispatch(updateUser(user));
//     dispatch(
//       updateToast({
//         toast: true,
//         message: "Logged in successfully",
//         type: 1,
//       })
//     );
//   } catch (error) {
//     dispatch(
//       updateToast({
//         toast: true,
//         message: "Login failed. Please try again.",
//         type: 0,
//       })
//     );
//   } finally {
//     dispatch(updateLoading(false)); 
//   }
// };
// const onSubmitHandle = (e) => {
//   e.preventDefault();
//   dispatch(loginUser(email, password));
// };