import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  updateAuthPopup,
  updateLoading,
  updateToast,
} from "../redux/global-slice";
import { updateUser } from "../redux/auth-slice";

export const authService = createApi({
  reducerPath: "authService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://reqres.in/api",
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: credentials,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(updateLoading(true));
          const { data } = await queryFulfilled;
          console.log(data);
          dispatch(updateUser(data));
          dispatch(updateAuthPopup(false));
          dispatch(
            updateToast({
              toast: true,
              message: "Logged in successfully!",
              type: 1,
            })
          );
        } catch (e) {
          console.log(e);
          const error = e.error.data.error ?? "Login failed. Please try again.";
          dispatch(
            updateToast({
              toast: true,
              message: error,
              type: 0,
            })
          );
        } finally {
          dispatch(updateLoading(false));
        }
      },
    }),
    registerUser: builder.mutation({
      query: (userData) => ({
        url: "/register", // Adjust the endpoint if needed
        method: "POST",
        body: userData,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(updateLoading(true));
          const { data } = await queryFulfilled;
          console.log(data);
          dispatch(
            updateToast({
              toast: true,
              message: "Registration successful!",
              type: 1,
            })
          );
        } catch (e) {
          console.error("Registration Error:", e);
          const error =
            e.error.data.error ?? "An error occurred. Please try again later.";
          dispatch(
            updateToast({
              toast: true,
              message: error,
              type: 0,
            })
          );
        } finally {
          dispatch(updateLoading(false));
        }
      },
    }),
  }),
});

export const { useLoginUserMutation, useRegisterUserMutation } = authService;
