import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="flex flex-col align-center justify-center gap4">
      <div>page not found</div>
      <Link to={"/"}>Back to Home</Link>
    </div>
  );
};
export default PageNotFound;
