import { useGetPostsQuery } from "../services/lists-service";
import TestimonialList from "../components/testimonial-list";
import PostsList from "../components/posts-list";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthPopup } from "../redux/global-slice";
import { userReducer } from "../redux/reducers";
import { logout } from "../redux/auth-slice";

const Home = () => {
  const dispatch = useDispatch();
  const user = useSelector(userReducer);
  const { isLoading, data, error } = useGetPostsQuery();

  return (
    <div className="body__container">
      <div
        className="flex align-center justify-center radius2"
        style={{
          height: "50vh",
          backgroundColor: "var(--primary-color)",
          color: "white",
          fontSize: "3rem",
        }}
      >
        {user ? (
          <button onClick={() => dispatch(logout())}>Logout</button>
        ) : (
          <button onClick={() => dispatch(updateAuthPopup(true))}>Login</button>
        )}
      </div>

      <TestimonialList />

      <PostsList posts={data} isLoading={isLoading} error={error} />
    </div>
  );
};

export default Home;
