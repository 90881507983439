import { createSlice } from "@reduxjs/toolkit";
import { listsAPIService } from "../services/lists-service";

const listsSlice = createSlice({
  name: "lists",
  initialState: {
    posts: [],
    comments: [],
    albums: [],
    photos: [],
    todos: [],
    users: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        listsAPIService.endpoints.getPosts.matchFulfilled,
        (state, action) => {
          state.posts = action.payload;
        }
      )
      .addMatcher(
        listsAPIService.endpoints.getComments.matchFulfilled,
        (state, action) => {
          state.comments = action.payload;
        }
      )
      .addMatcher(
        listsAPIService.endpoints.getAlbums.matchFulfilled,
        (state, action) => {
          state.albums = action.payload;
        }
      )
      .addMatcher(
        listsAPIService.endpoints.getPhotos.matchFulfilled,
        (state, action) => {
          state.photos = action.payload;
        }
      )
      .addMatcher(
        listsAPIService.endpoints.getTodos.matchFulfilled,
        (state, action) => {
          state.todos = action.payload;
        }
      )
      .addMatcher(
        listsAPIService.endpoints.getUsers.matchFulfilled,
        (state, action) => {
          state.users = action.payload;
        }
      );
  },
});

// Add a thunk to fetch posts on registration
// export const fetchPostsOnRegistration = () => async (dispatch) => {
//   try {
//     // Dispatch the getPosts query from postsService
//     const response = await listsAPIService.endpoints.getPosts.initiate();
//     // Wait for the query to complete
//     await response.data;
//   } catch (error) {
//     console.error("Error fetching posts:", error);
//   }
// };
// // Dispatch the thunk to fetch posts after successful registration
// dispatch(fetchPostsOnRegistration());

// export const {} = postsSlice.actions;
export default listsSlice.reducer;
