import { useNavigate } from "react-router-dom";
import photo from "../assets/medical-team.png";

const CoursesList = ({ title, lists }) => {
  const navigate = useNavigate();
  return (
    <div className="courses__list">
      <h1>{title}</h1>
      <div className="courses__list__container">
        {lists.map((e, i) => (
          <div
            key={i}
            onClick={() => {
              var id = e.toLowerCase().split(" ").join("-");
              navigate(`/courses/${id}`);
            }}
          >
            <img alt={e} src={photo} />
            <p>{e}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default CoursesList;
