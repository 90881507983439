import { useState } from "react";
import "../css/_tabbar.scss";

const Tabbar = ({ tabs = [] }) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className="tabbar">
      {/* Tab Headers */}
      <ul className="tabs__list">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`tabs__item ${index === activeTab ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      {/* Tab Content */}
      <div className={`tabs__content active`}>{tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabbar;
