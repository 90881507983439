import { useState } from "react";
import { useRegisterUserMutation } from "../../services/auth-service";

const RegisterComponent = () => {
  const [info, setInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
  });
  const [registerUser] = useRegisterUserMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    registerUser(info);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap3" style={{ gridTemplateColumns: "1fr 1fr" }}>
        <input
          type="text"
          placeholder="First Name"
          value={info.firstName}
          onChange={(e) => setInfo({ ...info, firstName: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Last Name"
          value={info.lastName}
          onChange={(e) => setInfo({ ...info, lastName: e.target.value })}
          required
        />
        <input
          type="email"
          placeholder="john@doe.com"
          value={info.email}
          onChange={(e) => setInfo({ ...info, email: e.target.value })}
          required
        />
        <input
          type="tel"
          placeholder="+977 9876543210"
          value={info.mobile}
          onChange={(e) => setInfo({ ...info, mobile: e.target.value })}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={info.password}
          onChange={(e) => setInfo({ ...info, password: e.target.value })}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={info.confirmPassword}
          onChange={(e) =>
            setInfo({ ...info, confirmPassword: e.target.value })
          }
          required
        />
      </div>
      <button type="submit">Register</button>
      {/* <div className="flex align-center justify-center gap2">
        <p>Already have an account?</p>
        <p>Log In</p>
      </div> */}
    </form>
  );
};

export default RegisterComponent;
