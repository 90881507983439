import { useState } from "react";

/* basic info: first name, middle name, last name, email, phone, gender, dob, about, photo */
const BasicInfo = () => {
  const [info, setInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "male",
    dob: "",
    about: "",
    photo: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form__group">
        <input
          type="text"
          placeholder="First Name"
          value={info.firstName}
          onChange={(e) => setInfo({ ...info, firstName: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Middle Name"
          value={info.middleName}
          onChange={(e) => setInfo({ ...info, middleName: e.target.value })}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={info.lastName}
          onChange={(e) => setInfo({ ...info, lastName: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Email"
          value={info.email}
          onChange={(e) => setInfo({ ...info, email: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Phone"
          value={info.phone}
          onChange={(e) => setInfo({ ...info, phone: e.target.value })}
          required
        />
        <div className="flex">
          <input
            type="date"
            placeholder="DOB"
            value={info.dob}
            onChange={(e) => setInfo({ ...info, dob: e.target.value })}
            required
          />
          <div style={{ minWidth: "50px" }}>
            <select
              value={info.gender}
              onChange={(e) => setInfo({ ...info, gender: e.target.value })}
              required
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
        </div>
      </div>

      <textarea placeholder="About" rows={5} />
      <button type="submit">Submit</button>
    </form>
  );
};

export default BasicInfo;
