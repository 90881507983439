import { useSelector } from "react-redux";
import { usersReducer } from "../redux/reducers";

const TestimoalCard = ({ i, user }) => {
  return user ? (
    <div className="testimonial__card p4 radius2 flex flex-col gap2">
      <h2>{user.name}</h2>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </p>
    </div>
  ) : (
    <div
      className="testimonial__card p4 radius2 flex flex-col gap2"
      style={{ backgroundColor: "var(--primary-color)" }}
    />
  );
};

const TestimonialList = () => {
  const users = useSelector(usersReducer);

  return (
    <div className="my4 radius2" style={{ overflowX: "scroll" }}>
      <div className="flex gap3">
        {users.length > 0
          ? users.map((user, i) => <TestimoalCard key={i} user={user} />)
          : Array.from({ length: 20 }).map((_, i) => <TestimoalCard key={i} />)}
      </div>
    </div>
  );
};

export default TestimonialList;
