import Loader from "./loader";

const PhotosList = ({ photos = [], isLoading = false, error }) => {
  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;
  return (
    <div className="photos__list grid">
      {photos.map((photo) => (
        <div key={photo.id} data-aos="zoom-in">
          <img alt={photo.title} src={photo.thumbnailUrl} />
        </div>
      ))}
    </div>
  );
};

export default PhotosList;
