import { useDispatch } from "react-redux";
import Tabbar from "../../components/tabbar";
import "../../css/_auth-popup.scss";
import LoginComponent from "./login-component";
import RegisterComponent from "./register-component";
import { updateAuthPopup } from "../../redux/global-slice";

const AuthPopup = () => {
  const dispatch = useDispatch();
  const tabs = [
    { label: "Login", content: <LoginComponent /> },
    { label: "Register", content: <RegisterComponent /> },
  ];

  return (
    <div
      className="auth__popup"
      onClick={(e) => {
        e.stopPropagation();
        dispatch(updateAuthPopup(false));
      }}
    >
      <div
        className="popup p10"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Tabbar tabs={tabs} />
      </div>
    </div>
  );
};

export default AuthPopup;
