import {
  BookOpen,
  LayoutDashboard,
  Bell,
  User,
  Home,
  Server,
  Building,
  ContactRound,
  Shell,
  Facebook,
  Instagram,
  Twitter,
  Youtube,
  Database,
} from "lucide-react";
import {
  ABOUT_ROUTE,
  CAREERS_ROUTE,
  CONTACT_ROUTE,
  HOME_ROUTE,
  COURSES_ROUTE,
  PROFILE_ROUTE,
  MYCOURSES_ROUTE,
  DASHBOARD_ROUTE,
  NOTIFICTION_ROUTE,
  SERVICES_ROUTE,
} from "./routes";
export const menuItems = [
  { title: "Home", path: HOME_ROUTE, icon: <Home /> },
  { title: "Courses", path: COURSES_ROUTE, icon: <Server /> },
];

export const profileMenuItems = [
  {
    title: "My Courses",
    path: MYCOURSES_ROUTE,
    icon: <BookOpen />,
  },
  {
    title: "Dashboard",
    path: DASHBOARD_ROUTE,
    icon: <LayoutDashboard />,
  },
  {
    title: "Notification",
    path: NOTIFICTION_ROUTE,
    icon: <Bell />,
  },
  {
    title: "Profile",
    path: PROFILE_ROUTE,
    icon: <User />,
  },
];

export const otherMenuItems = [
  // { title: "Home", path: HOME_ROUTE, icon: <Home /> },
  { title: "Services", path: SERVICES_ROUTE, icon: <Database /> },
  { title: "About Us", path: ABOUT_ROUTE, icon: <Building /> },
  { title: "Contact", path: CONTACT_ROUTE, icon: <ContactRound /> },
  { title: "Carrers", path: CAREERS_ROUTE, icon: <Shell /> },
];

export const socialsItems = [
  { key: "facebook", icon: <Facebook size={16} /> },
  { key: "instagram", icon: <Instagram size={16} /> },
  { key: "twitter", icon: <Twitter size={16} /> },
  { key: "youtube", icon: <Youtube size={16} /> },
];

export const whyJoinUS = [
  {
    title: "Fast Growing Company",
    body: "We are at an inflection point to achieve accelaration",
  },
  { title: "Greate Colleagues", body: "Closely tied and supportive team" },
  {
    title: "Take Change",
    body: "As much as you are willing to take and show excellence",
  },
  {
    title: "Don't Stop Learning",
    body: "An atmosphere where learning is always on top of to do list",
  },
  {
    title: "Latest Technology Stack",
    body: "Working experience at cutting edge technologies",
  },
  {
    title: "Cross Domain Exposure",
    body: "Highly passionate and cohassive team of technology and business people",
  },
];
