import Tabbar from "../components/tabbar";
import BasicInfo from "../components/info/basic_info";
import EducationInfo from "../components/info/education_info";
import GaurdianInfo from "../components/info/gaurdian_info";
import AddressInfo from "../components/info/address_info";

const Profile = () => {
  const tabs = [
    { label: "Basic Info", content: <BasicInfo /> },
    { label: "Gaurdian Info", content: <GaurdianInfo /> },
    { label: "Permanent Address", content: <AddressInfo /> },
    { label: "temparory Address", content: <AddressInfo /> },
    { label: "Education Info", content: <EducationInfo /> },
  ];
  return (
    <div className="body__container profile flex flex-col gap4">
      <Tabbar tabs={tabs} />
    </div>
  );
};

export default Profile;
