export const HOME_ROUTE = "/";
export const SERVICES_ROUTE = "services";
export const ABOUT_ROUTE = "about";
export const CONTACT_ROUTE = "contact";
export const CAREERS_ROUTE = "careers";
export const MYCOURSES_ROUTE = "my-courses";
export const DASHBOARD_ROUTE = "dashboard";
export const NOTIFICTION_ROUTE = "notification";
export const PROFILE_ROUTE = "profile";
export const COURSES_ROUTE = "courses";
export const EVENTS_ROUTE = "events";
export const PRIVACY_ROUTE = "privacy-policy";
export const TERMS_OF_USE_ROUTE = "terms-of-use";
export const NOT_FOUND_ROUTE = "*";
