import { NavLink, useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { menuItems, profileMenuItems } from "../../utils/constants";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { userReducer } from "../../redux/reducers";

const Header = () => {
  const navigate = useNavigate();
  const user = useSelector(userReducer);
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  const [menuVisiblity, setMenuVisibility] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    if (size[0] >= 600) {
      setMenuVisibility(user ? true : false);
    } else {
      setMenuVisibility(true);
    }
  }, [size, user]);

  // Call the function whenever toggleMenu changes
  useEffect(() => {
    document.body.style.overflow = toggleMenu ? "hidden" : "auto";
  }, [toggleMenu]);

  return (
    <header className="flex pos-sticky">
      <div className="menu__content flex align-center px4">
        <div
          className="logo"
          style={{ flex: 1, cursor: "pointer" }}
          onClick={() => navigate("/")}
        >
          Study2ool
        </div>
        <div className="menu__list flex">
          {menuItems.map((item, i) => (
            <NavLink
              key={i}
              to={item.path}
              className="flex align-center justify-center px4"
            >
              {item.title}
            </NavLink>
          ))}
        </div>
        {menuVisiblity && (
          <button
            className="link square"
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            {toggleMenu ? <X /> : <Menu />}
          </button>
        )}
        {toggleMenu && (
          <div className="mini__menu" onClick={() => setToggleMenu(false)}>
            <div className="mini__menu__container">
              {(size[0] <= 600 ? menuItems : profileMenuItems).map(
                (item, i) => (
                  <NavLink
                    key={i}
                    to={item.path}
                    className="flex align-center justify-center px4"
                    onClick={() => setToggleMenu(false)}
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </NavLink>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
